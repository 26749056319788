import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useAuth } from "../hooks";
import BlackButton from "../components/BlackButton";
import { useAtom } from 'jotai';
import { userAtom, rolesAtom } from "../atoms";
import { useNavigate } from 'react-router';

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  .logo-wrapper {
    width: 15rem;
    height: 5.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    a {
      text-decoration: none;
      color: black !important;
    }
  }
  .pc-nav-panel {
    width: 15rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--color-grey-00);
    padding: 0 0.5rem 0.5rem;
    box-sizing: border-box;

    .pc-navigation {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 12.5rem;
      box-sizing: border-box;
      &.logged {
        margin-top: 9.5rem;
      }
      &.tools {
        div.nav-container {
          padding: 0 0 0 3.25rem;
        }
      }
      div.nav-container {
        margin-bottom: 0.5rem;
        height: 2.75rem;
        display: flex;
        align-items: center;
        justify-content:  flex-start;
        text-decoration: none;
        color: black !important;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 600;
        padding: 0 0 0 2.83rem;
        box-sizing: border-box;
        border-radius: 3px;
        letter-spacing: 1.25px;
        &:hover {
          background-color: var(--color-gray-2);
        }
        &.selected {
          background-color: var(--color-grey-01);
        }
        &.auth {
          
        }
        
      }
    }
    .pc-made-in-nyc {
      font-size: 0.8rem;
      text-align: center;
    }
  }
  .pc-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
    position: relative;
  }
  &.mobile {
    .pc-content {
      
    }
  }
`;

const PageContainer = ({selected, title, hideNavPanel, hideNavBar, hideLogo, children, navGroup}) => {
  const navigate = useNavigate();
  const [user] =  useAtom(userAtom);
  const [roles] =  useAtom(rolesAtom);
  useEffect(() => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get("trial");
    if (!IS_MOBILE && email) {
    } else {
    }
  }, []);

  const getClassname = (id) => {
    if (selected === id) return 'nav-container selected';
    return 'nav-container';
  }

  const renderNavContent = () => {
    if (!user) return <div className={"pc-navigation"}>
      <div id="home" className={getClassname("home")} onClick={() => navigate("/")}>Home</div>
      <div id="get-started" className={getClassname("get-started")} onClick={() => navigate("/get-started")}>Get started</div>
      <div id="pricing" className={getClassname("pricing")} onClick={() => navigate("/pricing")}>Pricing</div>
    </div>
    if (navGroup === 'TOOLS') {
      return <div className={"pc-navigation logged tools"}>
        <div id="resume-builder" className={getClassname("resume-builder")} onClick={() => navigate("/tools/resume-builder")}>Resume Builder</div>
        <div id="qa-helper" className={getClassname("qa-helper")} onClick={() => navigate("/tools/qa-helper")}>QA Helper</div>
      </div>
    }
    return <div className={"pc-navigation logged"}>
      <div id="resume-assessment" className={getClassname("resume-assessment")} onClick={() => navigate("/resume-assessment")}>Resume<br/>Assessment</div>
      {
        roles &&
        <div id="roles" className={getClassname("roles")} onClick={() => navigate("/roles")}>Roles</div>
      }
      <div id="sourcing" className={getClassname("sourcing")} onClick={() => navigate("/sourcing")}>Sourcing</div>
      <div id="search" className={getClassname("search")} onClick={() => navigate("/search")}>Search</div>
      <div id="ats" className={getClassname("ats")} onClick={() => navigate("/ats")}>ATS</div>
      <div id="billing" className={getClassname("billing")} onClick={() => navigate("/billing")}>Billing</div>
    </div>
  }

  return (
      <Container className={IS_MOBILE ? "App mobile" : "App"}>
        {
          !hideNavPanel &&
          <div className="pc-nav-panel">
            {renderNavContent()}
            <div className="pc-made-in-nyc">Made in NYC</div>
          </div>
        }
        <div 
          className="pc-content"
          style={{marginLeft: hideNavPanel ? 0 : '15rem'}}
        >
          {children}
          <Footer></Footer>
        </div>  
        {
          !hideNavBar &&
          <NavBar title={title}/>
        } 
        {
          !hideLogo &&
          <div className="logo-wrapper">
            {
              user ?
              <h3>Honest Resume</h3>:
              <a href='/'><h3>Honest Resume</h3></a>
            }
          </div>
        }
      </Container>
     
  );
};

export default PageContainer;